import { Button } from '@/components/ui/button/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select';
import {
    usePolicyCustomDurationCreate,
    usePolicyCustomDurationDelete,
} from '@/hooks/mutations/policies/usePolicyCustomDuration';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomAppPolicyDurationType } from 'lib/prisma/enums';
import { capitalize } from 'lodash-es';
import { CircleX } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';

const AddCustomAccessLengthSchema = z.object({
    durationType: z.nativeEnum(CustomAppPolicyDurationType),
    durationValue: z.number().min(1).int(),
});

export const AddCustomAccessLengthModal = ({
    showModal,
    setShowModal,
}: { showModal: boolean; setShowModal: (showModal: boolean) => void }) => {
    const { mutate: createCustomDuration } = usePolicyCustomDurationCreate();
    const form = useForm({
        defaultValues: {
            durationType: CustomAppPolicyDurationType.HOURS,
            durationValue: 1,
        },
        resolver: zodResolver(AddCustomAccessLengthSchema),
    });
    const durationTypeOptions = Object.values(CustomAppPolicyDurationType).map(type => ({
        label: capitalize(type.toLowerCase()),
        value: type,
    }));

    const onSubmit = (values: z.infer<typeof AddCustomAccessLengthSchema>) => {
        createCustomDuration({
            duration_type: values.durationType,
            duration_value: values.durationValue,
        });
        setShowModal(false);
    };

    return (
        <Dialog
            open={showModal}
            onOpenChange={o => {
                setShowModal(o);
                if (!o) {
                    form.reset();
                }
            }}
        >
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Add custom access length</DialogTitle>
                </DialogHeader>

                <Form {...form}>
                    <form
                        className="flex flex-col gap-md p-lg items-start justify-center"
                        onSubmit={form.handleSubmit(onSubmit)}
                    >
                        <div className="flex flex-col gap-sm">
                            <FormLabel required htmlFor="durationValue">
                                Duration
                            </FormLabel>
                            <FormField
                                control={form.control}
                                name="durationValue"
                                render={({ field: { onChange, ...field } }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input {...field} onChange={e => onChange(Number(e.target.value))} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="flex flex-col gap-sm">
                            <FormLabel required htmlFor="durationType">
                                Duration Type
                            </FormLabel>
                            <FormField
                                control={form.control}
                                name="durationType"
                                render={({ field: { value, onChange, ...field } }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Select
                                                {...field}
                                                value={durationTypeOptions.find(option => option.value === value)}
                                                options={durationTypeOptions}
                                                onChange={option => onChange(option?.value)}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className="flex grow w-full justify-end">
                            <Button disabled={!form.formState.isDirty} type="submit" variant="blue" mode="dark">
                                Add
                            </Button>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
};

export const DeleteCustomAccessLengthModal = ({ id }: { id: string }) => {
    const { mutate: deleteCustomDuration, data } = usePolicyCustomDurationDelete(id);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (data && 'policiesThatWouldBreak' in data) {
            setShowModal(true);
        }
    }, [data]);

    return (
        <>
            <Button size="sm" mode="borderless" type="button" onClick={() => deleteCustomDuration()}>
                <CircleX />
            </Button>
            <Dialog open={showModal} onOpenChange={setShowModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Warning</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col gap-sm px-lg pb-lg">
                        <DialogDescription>
                            This duration is the only option for the following policies. Select alternatives for the
                            following policies before deleting.
                        </DialogDescription>
                        <div className="flex flex-col gap-sm">
                            {data &&
                                'policiesThatWouldBreak' in data &&
                                data.policiesThatWouldBreak.map(policy => (
                                    <Link
                                        key={policy.id}
                                        to={`/policies/${policy.id}`}
                                        className="flex items-center gap-sm"
                                    >
                                        <img
                                            src={policy.app.logo ?? undefined}
                                            alt={policy.app.name ?? undefined}
                                            className="size-4"
                                        />
                                        {policy.name}
                                    </Link>
                                ))}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
