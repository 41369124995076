import type { DefaultPolicySettings, PolicyActionTemplateResponse, PolicyDetailsResponse } from 'lib/models/app_policy';

import { useQuery } from '@/lib/hooks/useQuery';
import type { CustomAppPolicyDuration } from 'lib/prisma/types';

export const useDefaultPolicyConfig = () => {
    return useQuery<{ settings: DefaultPolicySettings | undefined }>({
        url: `/api/v1/policies/default-settings`,
        queryKey: ['default-policy-settings'],
    });
};

export const usePolicyActionTemplates = () => {
    return useQuery<PolicyActionTemplateResponse>({
        url: `/api/v1/policies/action-templates`,
    });
};

export const usePolicyDetails = (policyId: string | undefined) => {
    return useQuery<PolicyDetailsResponse>({
        url: `/api/v1/policies/${policyId}`,
        enabled: !!policyId,
    });
};

export const usePolicyCustomDurationOptions = () => {
    return useQuery<CustomAppPolicyDuration[]>({
        url: `/api/v1/policies/custom-duration-options`,
        queryKey: ['custom-duration-options'],
    });
};
